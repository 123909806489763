import payload_plugin_NEPHq8jEUC from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_UWUxmn85Fk from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/src/.nuxt/sentry-client-config.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_GeMvvjCziF from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import analytic_5Y9glehON6 from "/app/node_modules/@magnit/layer-profile/plugins/analytic.ts";
import piniaReset_FIZB958rQT from "/app/node_modules/@magnit/layer-profile/plugins/piniaReset.ts";
import toast_BUn5p6RJym from "/app/node_modules/@magnit/layer-profile/plugins/toast.ts";
import v_mask_2VzX6k4ofq from "/app/node_modules/@magnit/layer-profile/plugins/v-mask.ts";
import fingerprint_xuhW1yCaHy from "/app/node_modules/@magnit/layer-api/plugins/fingerprint.ts";
import transport_YEpzPT0A8Y from "/app/node_modules/@magnit/layer-api/plugins/transport.ts";
import error_ldt3PQauZ0 from "/app/src/plugins/error.ts";
import fingerprint_2ufeYYZ2V8 from "/app/src/plugins/fingerprint.ts";
import msw_client_EKy2ynTIMk from "/app/src/plugins/msw.client.ts";
import piniaReset_GJCT4uTrKH from "/app/src/plugins/piniaReset.ts";
export default [
  payload_plugin_NEPHq8jEUC,
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  sentry_client_UWUxmn85Fk,
  sentry_client_config_o34nk2sJbg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_GeMvvjCziF,
  analytic_5Y9glehON6,
  piniaReset_FIZB958rQT,
  toast_BUn5p6RJym,
  v_mask_2VzX6k4ofq,
  fingerprint_xuhW1yCaHy,
  transport_YEpzPT0A8Y,
  error_ldt3PQauZ0,
  fingerprint_2ufeYYZ2V8,
  msw_client_EKy2ynTIMk,
  piniaReset_GJCT4uTrKH
]