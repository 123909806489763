import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import type { Messaging } from "firebase/messaging";

const useFirebaseInit = () => {
  const messaging = ref<Messaging | null>(null);
  onMounted(async () => {
    const pushStore = usePushStore();
    const app = initializeApp(pushStore.fbaseConfig);
    const permissionStatus = navigator.permissions
      ? await navigator.permissions.query({
        name: "notifications",
      })
      : undefined;
    if (permissionStatus?.state === "granted") {
      messaging.value = getMessaging(app);
      onMessage(messaging.value, async (payload) => {
        const payloadData = payload?.data;
        if (navigator && payload) {
          const registration = await navigator.serviceWorker.getRegistration();
          navigator.serviceWorker?.controller?.postMessage({
            ...payloadData,
            type: "front-push-received",
          });
          if (registration) {
            await registration.showNotification(payload.notification?.title || "", {
              ...payload.notification,
              data: {
                ...payload.data,
                FCM_MSG: { data: { ...payload.data } },
              },
            });
          }
        }
      });
    }
  });
};

export default useFirebaseInit;
